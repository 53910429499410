var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.is_load)?_c('div',[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Trang chủ")]),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/admin/bus/lists"}},[_vm._v(" Quản lý Tuyến xe Bus")])],1),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Thêm mới Tuyến xe Bus")])]),_c('FormValidate',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',{attrs:{"id":"sendform"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"data-toggle":"tab","href":"#home"}},[_vm._v("Tiếng Việt")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#menu1"}},[_vm._v("English")])])]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"container tab-pane active",attrs:{"id":"home"}},[_c('br'),_c('div',{},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Thông tin chung ")]),_c('small',[_vm._v("Form")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Tuyến xe "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":"Tuyến xe ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.name),expression:"Form.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.Form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "name", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Lịch trình "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":" Lịch trình  ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.schedule),expression:"Form.schedule"}],staticClass:"form-control",attrs:{"type":"text","name":"schedule"},domProps:{"value":(_vm.Form.schedule)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "schedule", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Thời gian khởi hành "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":" Thời gian khởi hành  ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.timer),expression:"Form.timer"}],staticClass:"form-control",attrs:{"type":"text","name":"timer"},domProps:{"value":(_vm.Form.timer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "timer", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])])])])]),_c('div',{staticClass:"container tab-pane fade",attrs:{"id":"menu1"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":"Tuyến xe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.name_EN),expression:"Form.name_EN"}],staticClass:"form-control",attrs:{"type":"text","name":"name_en"},domProps:{"value":(_vm.Form.name_EN)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "name_EN", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Schedule "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":" Lịch trình  ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.schedule_EN),expression:"Form.schedule_EN"}],staticClass:"form-control",attrs:{"type":"text","name":"schedule_en"},domProps:{"value":(_vm.Form.schedule_EN)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "schedule_EN", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Timer "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":" Thời gian khởi hành  ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.timer_EN),expression:"Form.timer_EN"}],staticClass:"form-control",attrs:{"type":"text","name":"timer_en"},domProps:{"value":(_vm.Form.timer_EN)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "timer_EN", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('div',{staticClass:"card-footer",staticStyle:{"width":"90%","position":"fixed","bottom":"0"}},[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"submit"}},[_c('i',{staticClass:"fad fa-save"}),_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-sm btn-warning ml-3",attrs:{"type":"reset","disabled":invalid},on:{"click":_vm.myreset}},[_c('i',{staticClass:"fad fa-save"}),_vm._v(" Reset ")])])])])])]}}],null,false,1975241556)})],1):_c('div',[_c('MyLoadTheme',_vm._l((10),function(k){return _c('MyLoading',{key:k})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }